import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  ActionBox,
  Button,
  CheckPoint,
  Hero,
  SectionHeader,
  Link,
} from "../components/common";

import Item from "../components/item";

import Docs from "../assets/images/vectors/docs.svg";
import LoveTree from "../assets/images/vectors/love-tree.svg";
import Swirlbelow from "../assets/images/vectors/swirl-below.svg";

function NewsLetterPage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Newsletter"
        path={location?.pathname}
      />

      <section className="mt-20 mb-8">
        <Hero
          subTitle="Get Newsletter"
          title={
            <>
              Subscribe And Get Exclusive
              <br />
              Freebies Right After
            </>
          }
        />
      </section>
      <section className="mb-32 max-w-4xl m-auto">
        <form className="flex items-center w-full gap-x-4">
          <div className="flex-1">
            <input
              placeholder="email@example.com"
              className="py-5 px-6 border border-ui-gray-plus-1 rounded-lg w-full bg-none bg-opacity-0"
            />
          </div>
          <div className="inline-block relative">
            <Button
              text="Join 3,910+ Crafters"
              icon="vg-mail"
              hasMinWidth={false}
            />
          </div>
        </form>
        <div className="text-tiny pt-4">
          <div>
            <span /> I understand that Vackground can send me promotional emails
            and i can opt-out at any time
          </div>
        </div>
      </section>
      <section className="mb-32">
        <div className="mb-16">
          <SectionHeader
            art
            artVector={Docs}
            title="What You’ll Get"
            description="You will get this three premium products for free. Along with that, you will also get your first coupon code to purchase Vackground products."
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Item />
          <Item />
          <Item />
        </div>
      </section>
      <section className="mb-32">
        <div className="mb-16">
          <SectionHeader art artVector={LoveTree} title="Pure Benefits" />
        </div>
        <div className="flex items-center justify-center">
          <div className="flex flex-col gap-4">
            <CheckPoint content="Get the bundle of 3 premium products above instantly" />
            <CheckPoint content="Be the first to know about our new products" />
            <CheckPoint content="Get exclusive discounts specially for you, the subscriber" />
            <CheckPoint content="Blog articles, tutorials, tips and tricks delivered to your inbox" />
            <CheckPoint content="Exclusive partner deals, discounts and special bundle news" />
            <CheckPoint content="Be prepared for special day offers, before the special day" />
            <CheckPoint content="Quality newsletters, detailed, designed and yet minimalistic" />
          </div>
        </div>
      </section>
      <section className="mb-24">
        <div>
          <SectionHeader
            art
            artVector={Swirlbelow}
            title="Have Questions?"
            description="Your email is safe, we use it to make a personal connection to you and grant you an access to our secured data. There are some helpful links to learn more."
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <ActionBox icon="vg-file">
            Return to
            <br />
            <Link to="/">Vackground Home</Link>
          </ActionBox>
          <ActionBox icon="vg-file">
            Explore
            <br />
            <Link to="/">Vackground Products</Link>
          </ActionBox>
          <ActionBox icon="vg-file">
            Send Query at
            <br />
            <Link to="/">hi@vackground.com</Link>
          </ActionBox>
        </div>
      </section>
    </Layout>
  );
}

NewsLetterPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default NewsLetterPage;
